<template>
  <div class="properties-section">
    <nav class="d-flex" :class="{ 'mb-4': view !== 'map' }">
      <div class="property-filters">
        <span class="search-properties">
          <img
            class="hourglass"
            src="/wp-content/themes/sterling-organization/src/components/Properties/assets/hourglass.png"
            alt="hourglass icon"
          />
          <label for="search-properties" class="sr-only"
            >Search Properties</label
          >
          <b-form-input
            id="search-properties"
            class="input__search"
            v-model="inputSearch"
            placeholder="Search by Property Name, City, MSA, State or Zip"
          ></b-form-input>
        </span>
        <span class="search-anchors modified">
          <img
            class="hourglass"
            src="/wp-content/themes/sterling-organization/src/components/Properties/assets/hourglass.png"
            alt="hourglass icon"
          />
          <label for="search-anchors" class="sr-only"
            >Search by Major Tenants or National Retailers</label
          >
          <b-form-input
            id="search-anchors"
            class="input__search"
            v-model="anchors"
            placeholder="Search by Major Tenants or National Retailers"
          ></b-form-input>
        </span>
        <b-dropdown variant="outline-secondary">
          <template v-slot:button-content>
            <span>{{
              square_ft_range.selected === "any"
                ? "Filter By Sq. Ft."
                : square_ft_range.options.filter(
                    (x) => x.value == square_ft_range.selected
                  )[0].name
            }}</span>
          </template>
          <b-dropdown-item
            v-for="item in square_ft_range.options"
            :key="item.name"
            @click="updateSearch(item, 'square_ft_range')"
            >{{ item.name }}</b-dropdown-item
          >
        </b-dropdown>
        <b-dropdown variant="outline-secondary">
          <template v-slot:button-content>
            <span>{{
              agents.selected === "Any"
                ? "Filter By Agent"
                : agents.options.filter((x) => x.name === agents.selected)[0]
                    .name
            }}</span>
          </template>
          <b-dropdown-item
            v-for="item in agents.options"
            :key="item.name"
            @click="updateSearch(item.name, 'agents')"
            >{{ item.name }}</b-dropdown-item
          >
        </b-dropdown>
        <b-dropdown variant="outline-secondary">
          <template v-slot:button-content>
            <span>{{
              availability.selected === "Any"
                ? "Filter by Availability"
                : availability.selected
            }}</span>
          </template>
          <b-dropdown-item
            v-for="item in availability.options"
            :key="item.slug"
            @click="updateSearch(item.name, 'availability')"
            >{{ item.name }}</b-dropdown-item
          >
        </b-dropdown>
      </div>
      <div class="d-flex align-items-center">
        <div class="list-icons">
          <!-- <button class="btn btn-outline-dark" @click="clearFilters()">Clear Filters</button> -->
          <button
            class="btn btn-link px-1 view-icon"
            @click="switchView('map')"
          >
            <svg
              :class="{ active: view === 'map' }"
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <rect
                  fill="none"
                  id="canvas_background"
                  height="402"
                  width="582"
                  y="-1"
                  x="-1"
                />
              </g>
              <g>
                <title>Map View</title>
                <path
                  id="svg_1"
                  d="m12,1c-3.148,0 -6,2.553 -6,5.702c0,3.148 2.602,6.907 6,12.298c3.398,-5.391 6,-9.15 6,-12.298c0,-3.149 -2.851,-5.702 -6,-5.702zm0,8c-1.105,0 -2,-0.895 -2,-2s0.895,-2 2,-2s2,0.895 2,2s-0.895,2 -2,2zm12,14l-24,0l4,-8l3.135,0c0.385,0.641 0.798,1.309 1.232,2l-3.131,0l-2,4l17.527,0l-2,-4l-3.131,0c0.435,-0.691 0.848,-1.359 1.232,-2l3.136,0l4,8z"
                />
              </g>
            </svg>
          </button>
          <button
            class="btn btn-link px-1 view-icon"
            :class="{ 'text-muted': view !== 'list' }"
            @click="switchView('list')"
          >
            <svg
              :class="{ active: view === 'list' }"
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <rect
                  fill="none"
                  id="canvas_background"
                  height="402"
                  width="582"
                  y="-1"
                  x="-1"
                />
              </g>
              <g>
                <title>List View</title>
                <path
                  id="svg_1"
                  d="m4,22l-4,0l0,-4l4,0l0,4zm0,-12l-4,0l0,4l4,0l0,-4zm0,-8l-4,0l0,4l4,0l0,-4zm3,0l0,4l17,0l0,-4l-17,0zm0,12l17,0l0,-4l-17,0l0,4zm0,8l17,0l0,-4l-17,0l0,4z"
                />
              </g>
            </svg>
          </button>
          <button
            class="btn btn-link px-1 view-icon"
            :class="{ 'text-muted': view !== 'grid' }"
            @click="switchView('grid')"
          >
            <svg
              :class="{ active: view === 'grid' }"
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <rect
                  fill="none"
                  id="canvas_background"
                  height="402"
                  width="582"
                  y="-1"
                  x="-1"
                />
              </g>
              <g>
                <title>Grid View</title>
                <path
                  stroke="#fff"
                  id="svg_1"
                  d="m11,11l-11,0l0,-11l11,0l0,11zm13,0l-11,0l0,-11l11,0l0,11zm-13,13l-11,0l0,-11l11,0l0,11zm13,0l-11,0l0,-11l11,0l0,11z"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
    </nav>
    <div class="container mb-3" v-if="view === 'grid'">
      <div class="row property-grid" v-if="displayedProperties.length">
        <h5 class="text-dark col-12">
          Displaying {{ displayedProperties.length }} of {{ properties.length }}
        </h5>
        <div
          v-for="property in displayedProperties"
          v-bind:key="property.id"
          class="col-12 col-md-4 column"
        >
          <a :href="property.link">
            <div
              class="wrapper"
              :style="{
                backgroundImage:
                  property.propertyImage &&
                  `url(${
                    property.propertyImage !== 'false'
                      ? property.propertyImage
                      : '/wp-content/themes/sterling-organization/images/post-fallback.jpg'
                  })`,
              }"
            >
              <div class="availability" v-if="property.availability">
                <span>
                  <svg
                    width="15"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <title>background</title>
                      <rect
                        fill="none"
                        id="canvas_background"
                        height="17"
                        width="17"
                        y="-1"
                        x="-1"
                      />
                    </g>
                    <g>
                      <title>Layer 1</title>
                      <path
                        fill="#ffffff"
                        stroke="null"
                        id="svg_1"
                        d="m7.5,1.25c3.44625,0 6.25,2.80375 6.25,6.25s-2.80375,6.25 -6.25,6.25s-6.25,-2.80375 -6.25,-6.25s2.80375,-6.25 6.25,-6.25zm0,-1.25c-4.141875,0 -7.5,3.358125 -7.5,7.5s3.358125,7.5 7.5,7.5s7.5,-3.358125 7.5,-7.5s-3.358125,-7.5 -7.5,-7.5zm2.745625,4.6875l-3.526875,3.615l-1.6525,-1.56625l-1.16,1.16125l2.8125,2.7275l4.6875,-4.776875l-1.160625,-1.160625z"
                      />
                    </g>
                  </svg>
                </span>
                <span>Available</span>
              </div>
            </div>
            <div class="content p-3">
              <p class="title" v-html="property.title"></p>
              <p class="city m-0">
                {{ property.msa ? `${property.msa}` : "USA" }} (MSA)
              </p>
              <a :href="property.link" class="btn link pull-right text-white"
                >View</a
              >
            </div>
          </a>
        </div>
      </div>
      <div class="row" v-else>
        <div class="no-properties" v-if="!loaded">
          <div class="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="no-properties" v-else>
          No Properties Matching Search Criteria
        </div>
      </div>
    </div>
    <div class="container mb-4" v-if="view === 'list'">
      <div class="row">
        <h5 class="text-dark">
          Displaying {{ displayedProperties.length }} of {{ properties.length }}
        </h5>
        <b-table
          striped
          hover
          :fields="fields"
          @row-clicked="goToPropertyDetails"
          :items="formatListData(displayedProperties)"
        >
          <template v-slot:cell(name)="data">
            <p v-html="data.item.name"></p>
          </template>
        </b-table>
      </div>
    </div>
    <div class="container-fluid" v-if="view === 'map'">
      <div class="row">
        <div style="width: 100%; min-height: 700px">
          <GmapMap
            :center="{ lat: 38.3, lng: -98.5795 }"
            :zoom="5"
            style="width: 100%; height: 100%"
            :options="{ styles: mapStyles }"
          >
            <gmap-info-window
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen = false"
            ></gmap-info-window>
            <GmapMarker
              :key="index"
              v-for="(m, index) in displayedProperties"
              :position="m.position"
              :clickable="true"
              @mouseover="toggleInfoWindow(m, index)"
              :icon="{
                url:
                  '/wp-content/themes/sterling-organization/src/components/Properties/assets/marker-sm.png',
              }"
            />
          </GmapMap>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Fuse from "fuse.js";
import $ from "jquery";

import { checkmark, minus } from "./assets/svgs";
import { updateSearch, inputSearch, yardiOrder, mapOrder } from "./helpers";

import { options, anchors_options, GetProperties, data } from "./configs";

function findOptionBySize(size, options) {
  if (typeof size !== "number" || size < 0) {
    return "Invalid input. Size must be a non-negative number.";
  }

  for (const option of options) {
    const name = option.name;
    if (name === "Any") {
      continue;
    } else if (name.startsWith("Above")) {
      const minSize = parseInt(name.replace(/,/g, "").match(/\d+/)[0]);
      if (size > minSize) return option;
    } else if (name.includes("-")) {
      const [minSize, maxSize] = name
        .replace(/,/g, "")
        .match(/\d+/g)
        .map(Number);
      if (size >= minSize && size <= maxSize) return option;
    } else if (name.startsWith("Under")) {
      const maxSize = parseInt(name.replace(/,/g, "").match(/\d+/)[0]);
      if (size < maxSize) return option;
    }
  }

  return "No matching option found.";
}

export default {
  props: [],
  components: {},
  data() {
    return data;
  },
  watch: {
    availability: updateSearch,
    anchors: updateSearch,
    agents: updateSearch,
    loaded: updateSearch,
  },
  computed: {
    inputSearch,
  },
  methods: {
    orderById: function(a, b) {
      const id_1 = a.yardiId;
      const id_2 = b.yardiId;

      return id_1 - id_2;
    },
    alphabetize: function(a, b) {
      return a.title.localeCompare(b.title);
    },
    alphabetizeAgents: function(a, b) {
      return a.name.localeCompare(b.name);
    },
    goToPropertyDetails: function(record, idx) {
      const property = this.properties.filter((item) => {
        return item.title === record.name;
      });
      if (property.length) {
        window.location.href = property[0].link;
      }
    },
    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    allFiltersClean() {
      return Object.keys(this.allFilters).every(
        function(filter) {
          return this.allFilters[filter].selected
            ? this.allFilters[filter].selected.toLowerCase() !== "any"
            : !this.allFilters[filter];
        }.bind(this)
      );
    },
    reEvaluateFilters(type) {
      this.updateAllFilters();
      let filtered = this.properties;

      if (this.search) {
        const fuzzy = new Fuse(this.properties, options);
        filtered = fuzzy.search(this.search);
      }

      if (this.anchors) {
        const fuzzy_anchors = new Fuse(filtered, anchors_options);
        filtered = fuzzy_anchors.search(this.anchors);
      }

      filtered = filtered.filter(
        function({ availability }) {
          if (this.availability.selected === "Available") {
            return availability === true;
          } else if (this.availability.selected === "Not Available") {
            return !availability;
          } else {
            return true;
          }
        }.bind(this)
      );

      filtered = filtered.filter(
        function({ sqft_options }) {
          let selectedOption = this.square_ft_range.selected;
          return selectedOption == "any" || (selectedOption && sqft_options[selectedOption]);
        }.bind(this)
      );

      let selectedOption = this.agents.selected;
      filtered = filtered.filter(
        function({ leasingManager, leasingAssociate, propertyManager }) {
          return (
            selectedOption == "Any" ||
            leasingManager === selectedOption ||
            leasingAssociate === selectedOption ||
            propertyManager === selectedOption
          );
        }.bind(this)
      );

      return filtered;
    },
    updateAllFilters() {
      this.allFilters = {
        availability: this.availability,
        search: this.search,
        anchors: this.anchors,
        agents: this.agents,
        square_ft_range: this.square_ft_range,
      };
    },
    formatListData(items) {
      function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      var decodeHTML = function(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
      };
      return items.map((item) => {
        const locationArr = item.city && item.city.split(", ");
        let msaCity = item.msa.split(",");

        return {
          name: decodeHTML(item.title),
          city: item.city,
          state: item.state,
          msa: msaCity.length === 2 ? msaCity[0] : "",
          gla: item.squareFootage
            ? numberWithCommas(parseFloat(item.squareFootage))
            : 0,
          availability: item.availability ? "Availability" : "Not Available",
        };
      });
    },
    updateSearch: updateSearch,
    clearFilters() {
      this.availability.selected = "Any";
      this.square_ft_range.selected = "any";
      this.search = "";
      this.anchors = "";
      this.agents = "Any";
      this.displayedProperties = this.properties;
    },
    updateAvailabilitySvgs() {
      const that = this;

      function pluck(array, key, value) {
        return array.filter((o) => {
          return o[key] == value;
        })[0];
      }

      $("tbody tr").each(function(item) {
        let name = $(this)
          .find("td:first-of-type")
          .text();
        let last = $(this).find("td:last-of-type");

        const rowItem = pluck(that.displayedProperties, "title", name);

        if (rowItem.availability) {
          last.empty();
          last.append(checkmark);
          last.find("svg").animate(
            {
              opacity: 1,
            },
            2000
          );
        } else {
          last.empty();
          last.append(minus);
          last.find("svg").animate(
            {
              opacity: 1,
            },
            2000
          );
        }
      });
    },
    switchView(view) {
      this.view = view;
      if (view == "list") {
        setTimeout(this.updateAvailabilitySvgs, 0);
      }
    },
    formatMapData(properties) {
      var decodeHTML = function(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
      };

      function formatAnchors(property) {
        const { anchors } = property;
        const anchorList = [];
        if (anchors.nodes && anchors.nodes.length > 0) {
          for (let i = 0; i < anchors.nodes.length; i++) {
            (function() {
              anchorList.push(anchors.nodes[i].name);
            })(i);
          }
        }
        property.anchors = anchorList;
      }

      return properties.map((property) => {
        formatAnchors(property);

        property.title = unescape(decodeHTML(property.title));

        property.position = {
          lat: parseFloat(property.latitude),
          lng: parseFloat(property.longitude),
        };

        property.infoText = `
          <div class="marker-window">
            <div class="featured-image" style="
              background-image: url(${
                property.propertyImage !== "false"
                  ? property.propertyImage
                  : "/wp-content/themes/sterling-organization/images/post-fallback.jpg"
              });
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            "></div>
            <div class="info-content">
              <h4 class="title">${property.title}</h4>
              <div class="city-state">${property.msa ||
                "coming soon"} (MSA)</div>
              <a class="view" href="${property.link}">View</a>
            </div>
          </div>
        `;

        return property;
      });
    },
  },
  beforeCreate() {},
  created() {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("search")) {
      this.search = searchParams.get("search");
      window.history.pushState({}, document.title, "/properties");
    }
    this.fuzzy = new Fuse(window.properties, options);
    const uniqueAgents = new Set();
    this.agents.options = window.properties
      .reduce((acc, { leasingManager, leasingAssociate, propertyManager }) => {
        if (leasingManager && leasingManager !== "N/A") {
          if (!uniqueAgents.has(leasingManager)) {
            uniqueAgents.add(leasingManager);
            acc.push({ name: leasingManager });
          }
        }

        if (leasingAssociate && leasingAssociate !== "N/A") {
          if (!uniqueAgents.has(leasingAssociate)) {
            uniqueAgents.add(leasingAssociate);
            acc.push({ name: leasingAssociate });
          }
        }
        return acc;
      }, [])
      .sort(this.alphabetizeAgents);

    this.agents.options.unshift({ name: "Any" });
    this.displayedProperties = this.formatMapData(window.properties);
    this.properties = window.properties;
    this.loaded = true;
  },
};
</script>
