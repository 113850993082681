var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "properties-section" }, [
    _c(
      "nav",
      { staticClass: "d-flex", class: { "mb-4": _vm.view !== "map" } },
      [
        _c(
          "div",
          { staticClass: "property-filters" },
          [
            _c(
              "span",
              { staticClass: "search-properties" },
              [
                _c("img", {
                  staticClass: "hourglass",
                  attrs: {
                    src: "/wp-content/themes/sterling-organization/src/components/Properties/assets/hourglass.png",
                    alt: "hourglass icon",
                  },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "sr-only",
                    attrs: { for: "search-properties" },
                  },
                  [_vm._v("Search Properties")]
                ),
                _vm._v(" "),
                _c("b-form-input", {
                  staticClass: "input__search",
                  attrs: {
                    id: "search-properties",
                    placeholder:
                      "Search by Property Name, City, MSA, State or Zip",
                  },
                  model: {
                    value: _vm.inputSearch,
                    callback: function ($$v) {
                      _vm.inputSearch = $$v
                    },
                    expression: "inputSearch",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "search-anchors modified" },
              [
                _c("img", {
                  staticClass: "hourglass",
                  attrs: {
                    src: "/wp-content/themes/sterling-organization/src/components/Properties/assets/hourglass.png",
                    alt: "hourglass icon",
                  },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  { staticClass: "sr-only", attrs: { for: "search-anchors" } },
                  [_vm._v("Search by Major Tenants or National Retailers")]
                ),
                _vm._v(" "),
                _c("b-form-input", {
                  staticClass: "input__search",
                  attrs: {
                    id: "search-anchors",
                    placeholder:
                      "Search by Major Tenants or National Retailers",
                  },
                  model: {
                    value: _vm.anchors,
                    callback: function ($$v) {
                      _vm.anchors = $$v
                    },
                    expression: "anchors",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-dropdown",
              {
                attrs: { variant: "outline-secondary" },
                scopedSlots: _vm._u([
                  {
                    key: "button-content",
                    fn: function () {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.square_ft_range.selected === "any"
                                ? "Filter By Sq. Ft."
                                : _vm.square_ft_range.options.filter(
                                    (x) =>
                                      x.value == _vm.square_ft_range.selected
                                  )[0].name
                            )
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(" "),
                _vm._l(_vm.square_ft_range.options, function (item) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: item.name,
                      on: {
                        click: function ($event) {
                          return _vm.updateSearch(item, "square_ft_range")
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "b-dropdown",
              {
                attrs: { variant: "outline-secondary" },
                scopedSlots: _vm._u([
                  {
                    key: "button-content",
                    fn: function () {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.agents.selected === "Any"
                                ? "Filter By Agent"
                                : _vm.agents.options.filter(
                                    (x) => x.name === _vm.agents.selected
                                  )[0].name
                            )
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(" "),
                _vm._l(_vm.agents.options, function (item) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: item.name,
                      on: {
                        click: function ($event) {
                          return _vm.updateSearch(item.name, "agents")
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "b-dropdown",
              {
                attrs: { variant: "outline-secondary" },
                scopedSlots: _vm._u([
                  {
                    key: "button-content",
                    fn: function () {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.availability.selected === "Any"
                                ? "Filter by Availability"
                                : _vm.availability.selected
                            )
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(" "),
                _vm._l(_vm.availability.options, function (item) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: item.slug,
                      on: {
                        click: function ($event) {
                          return _vm.updateSearch(item.name, "availability")
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("div", { staticClass: "list-icons" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-link px-1 view-icon",
                on: {
                  click: function ($event) {
                    return _vm.switchView("map")
                  },
                },
              },
              [
                _c(
                  "svg",
                  {
                    class: { active: _vm.view === "map" },
                    attrs: {
                      width: "24",
                      height: "24",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("g", [
                      _c("rect", {
                        attrs: {
                          fill: "none",
                          id: "canvas_background",
                          height: "402",
                          width: "582",
                          y: "-1",
                          x: "-1",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("g", [
                      _c("title", [_vm._v("Map View")]),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          id: "svg_1",
                          d: "m12,1c-3.148,0 -6,2.553 -6,5.702c0,3.148 2.602,6.907 6,12.298c3.398,-5.391 6,-9.15 6,-12.298c0,-3.149 -2.851,-5.702 -6,-5.702zm0,8c-1.105,0 -2,-0.895 -2,-2s0.895,-2 2,-2s2,0.895 2,2s-0.895,2 -2,2zm12,14l-24,0l4,-8l3.135,0c0.385,0.641 0.798,1.309 1.232,2l-3.131,0l-2,4l17.527,0l-2,-4l-3.131,0c0.435,-0.691 0.848,-1.359 1.232,-2l3.136,0l4,8z",
                        },
                      }),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-link px-1 view-icon",
                class: { "text-muted": _vm.view !== "list" },
                on: {
                  click: function ($event) {
                    return _vm.switchView("list")
                  },
                },
              },
              [
                _c(
                  "svg",
                  {
                    class: { active: _vm.view === "list" },
                    attrs: {
                      width: "24",
                      height: "24",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("g", [
                      _c("rect", {
                        attrs: {
                          fill: "none",
                          id: "canvas_background",
                          height: "402",
                          width: "582",
                          y: "-1",
                          x: "-1",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("g", [
                      _c("title", [_vm._v("List View")]),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          id: "svg_1",
                          d: "m4,22l-4,0l0,-4l4,0l0,4zm0,-12l-4,0l0,4l4,0l0,-4zm0,-8l-4,0l0,4l4,0l0,-4zm3,0l0,4l17,0l0,-4l-17,0zm0,12l17,0l0,-4l-17,0l0,4zm0,8l17,0l0,-4l-17,0l0,4z",
                        },
                      }),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-link px-1 view-icon",
                class: { "text-muted": _vm.view !== "grid" },
                on: {
                  click: function ($event) {
                    return _vm.switchView("grid")
                  },
                },
              },
              [
                _c(
                  "svg",
                  {
                    class: { active: _vm.view === "grid" },
                    attrs: {
                      width: "24",
                      height: "24",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("g", [
                      _c("rect", {
                        attrs: {
                          fill: "none",
                          id: "canvas_background",
                          height: "402",
                          width: "582",
                          y: "-1",
                          x: "-1",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("g", [
                      _c("title", [_vm._v("Grid View")]),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          stroke: "#fff",
                          id: "svg_1",
                          d: "m11,11l-11,0l0,-11l11,0l0,11zm13,0l-11,0l0,-11l11,0l0,11zm-13,13l-11,0l0,-11l11,0l0,11zm13,0l-11,0l0,-11l11,0l0,11z",
                        },
                      }),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.view === "grid"
      ? _c("div", { staticClass: "container mb-3" }, [
          _vm.displayedProperties.length
            ? _c(
                "div",
                { staticClass: "row property-grid" },
                [
                  _c("h5", { staticClass: "text-dark col-12" }, [
                    _vm._v(
                      "\n        Displaying " +
                        _vm._s(_vm.displayedProperties.length) +
                        " of " +
                        _vm._s(_vm.properties.length) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.displayedProperties, function (property) {
                    return _c(
                      "div",
                      {
                        key: property.id,
                        staticClass: "col-12 col-md-4 column",
                      },
                      [
                        _c("a", { attrs: { href: property.link } }, [
                          _c(
                            "div",
                            {
                              staticClass: "wrapper",
                              style: {
                                backgroundImage:
                                  property.propertyImage &&
                                  `url(${
                                    property.propertyImage !== "false"
                                      ? property.propertyImage
                                      : "/wp-content/themes/sterling-organization/images/post-fallback.jpg"
                                  })`,
                              },
                            },
                            [
                              property.availability
                                ? _c("div", { staticClass: "availability" }, [
                                    _c("span", [
                                      _c(
                                        "svg",
                                        {
                                          attrs: {
                                            width: "15",
                                            height: "15",
                                            xmlns: "http://www.w3.org/2000/svg",
                                          },
                                        },
                                        [
                                          _c("g", [
                                            _c("title", [_vm._v("background")]),
                                            _vm._v(" "),
                                            _c("rect", {
                                              attrs: {
                                                fill: "none",
                                                id: "canvas_background",
                                                height: "17",
                                                width: "17",
                                                y: "-1",
                                                x: "-1",
                                              },
                                            }),
                                          ]),
                                          _vm._v(" "),
                                          _c("g", [
                                            _c("title", [_vm._v("Layer 1")]),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                fill: "#ffffff",
                                                stroke: "null",
                                                id: "svg_1",
                                                d: "m7.5,1.25c3.44625,0 6.25,2.80375 6.25,6.25s-2.80375,6.25 -6.25,6.25s-6.25,-2.80375 -6.25,-6.25s2.80375,-6.25 6.25,-6.25zm0,-1.25c-4.141875,0 -7.5,3.358125 -7.5,7.5s3.358125,7.5 7.5,7.5s7.5,-3.358125 7.5,-7.5s-3.358125,-7.5 -7.5,-7.5zm2.745625,4.6875l-3.526875,3.615l-1.6525,-1.56625l-1.16,1.16125l2.8125,2.7275l4.6875,-4.776875l-1.160625,-1.160625z",
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Available")]),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "content p-3" }, [
                            _c("p", {
                              staticClass: "title",
                              domProps: { innerHTML: _vm._s(property.title) },
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "city m-0" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    property.msa ? `${property.msa}` : "USA"
                                  ) +
                                  " (MSA)\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "btn link pull-right text-white",
                                attrs: { href: property.link },
                              },
                              [_vm._v("View")]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              )
            : _c("div", { staticClass: "row" }, [
                !_vm.loaded
                  ? _c("div", { staticClass: "no-properties" }, [_vm._m(0)])
                  : _c("div", { staticClass: "no-properties" }, [
                      _vm._v(
                        "\n        No Properties Matching Search Criteria\n      "
                      ),
                    ]),
              ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.view === "list"
      ? _c("div", { staticClass: "container mb-4" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("h5", { staticClass: "text-dark" }, [
                _vm._v(
                  "\n        Displaying " +
                    _vm._s(_vm.displayedProperties.length) +
                    " of " +
                    _vm._s(_vm.properties.length) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("b-table", {
                attrs: {
                  striped: "",
                  hover: "",
                  fields: _vm.fields,
                  items: _vm.formatListData(_vm.displayedProperties),
                },
                on: { "row-clicked": _vm.goToPropertyDetails },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(name)",
                      fn: function (data) {
                        return [
                          _c("p", {
                            domProps: { innerHTML: _vm._s(data.item.name) },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2894710380
                ),
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.view === "map"
      ? _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticStyle: { width: "100%", "min-height": "700px" } },
              [
                _c(
                  "GmapMap",
                  {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: {
                      center: { lat: 38.3, lng: -98.5795 },
                      zoom: 5,
                      options: { styles: _vm.mapStyles },
                    },
                  },
                  [
                    _c("gmap-info-window", {
                      attrs: {
                        options: _vm.infoOptions,
                        position: _vm.infoWindowPos,
                        opened: _vm.infoWinOpen,
                      },
                      on: {
                        closeclick: function ($event) {
                          _vm.infoWinOpen = false
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.displayedProperties, function (m, index) {
                      return _c("GmapMarker", {
                        key: index,
                        attrs: {
                          position: m.position,
                          clickable: true,
                          icon: {
                            url: "/wp-content/themes/sterling-organization/src/components/Properties/assets/marker-sm.png",
                          },
                        },
                        on: {
                          mouseover: function ($event) {
                            return _vm.toggleInfoWindow(m, index)
                          },
                        },
                      })
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lds-grid" }, [
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }