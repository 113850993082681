<template>
  <div class="interactive-map" @mouseout="unpointLocation">
    <div class="interactive-map__container">
      <div class="interactive-map__controls">
        <div class="interactive-map__zoom-in" v-on:click="zoomIn">
          <font-awesome-icon
            class="interactive-map__square interactive-map__square--plus"
            :icon="['far', 'plus-square']"
          />
        </div>
        <div class="interactive-map__zoom-out" v-on:click="zoomOut">
          <font-awesome-icon
            class="interactive-map__square interactive-map__square--minus"
            :icon="['far', 'minus-square']"
          />
        </div>
        <div class="interactive-map__center" v-on:click="center">
          <font-awesome-icon
            class="interactive-map__square interactive-map__square--center"
            :icon="['far', 'dot-circle']"
          />
        </div>
      </div>
      <div id="interactive-map__scene" class="interactive-map__map">
        <v-style type="text/css">{{
          style.children && style.children[0].value
        }}</v-style>
        <div class="interactive-map__map-fallback">
          <img :src="fallback" @click="unpointLocation" />
        </div>
        <radio-svg-map
          class="interactive-map__map-radio--hovered"
          v-model="selectedLocation"
          :map="hoveredMap"
          @mouseout="unpointLocation"
        />
        <radio-svg-map
          class="interactive-map__map-radio"
          :map="currentMap"
          @mouseover="pointLocation"
        />
      </div>
      <div class="interactive-map__window" v-if="pointedLocation">
        <div class="interactive-map__unit interactive-map__unit-name">
          <span>Tenant Name:</span>
          {{ pointedLocation.tenant_name || "Available" }}
        </div>
        <div class="interactive-map__unit interactive-map__unit-code">
          <span>Space:</span>
          {{ pointedLocation.unit_code }}
        </div>
        <div class="interactive-map__unit interactive-map__unit-sqft">
          <span>Sq.Ft:</span>
          {{ pointedLocation.unit_sqft }}
        </div>
      </div>
    </div>
    <div class="interactive-map__tenant-table mt-4">
      <div id="table">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Space</th>
              <th scope="col">Tenant</th>
              <th scope="col">Sq. Ft.</th>
              <th scope="col">Take a Virtual Tour</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :key="idx"
              v-for="(tenant, idx) in tenants"
              :class="{
                available:
                  tenant.tenant_name &&
                  tenant.tenant_name.includes('Available'),
                'interactive-map__row--hovered':
                  pointedLocation &&
                  pointedLocation.unit_code === tenant.unit_code,
              }"
            >
              <th scope="row">{{ tenant.unit_code }}</th>
              <td>{{ tenant.tenant_name }}</td>
              <td>{{ tenant.unit_sqft }}</td>
              <td>
                <a
                  :href="tenant.virtual_tour_link"
                  target="_blank"
                  class="interactive-map__tour-link"
                  v-if="tenant.virtual_tour_link"
                  ><font-awesome-icon
                    class="interactive-map__arrow"
                    :icon="['fas', 'angle-right']"
                  /><span style="margin-top: 4px">View Details</span></a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import svgson from "svgson";
import { RadioSvgMap } from "vue-svg-map";
import axios from "axios";
import panzoom from "@panzoom/panzoom";
import {
  // getLocationName,
  // getHoveredMapPaths,
  // getMapPaths,
  getCurrentMapConfig,
  getHoveredMapConfig,
  getLocation,
  // formatLocationData,
  getSelectedLocationName,
  addAvaiabilityToSvgMap,
} from "./helpers";

Vue.component("v-style", {
  render: function (createElement) {
    return createElement("style", this.$slots.default);
  },
});

export default {
  props: ["svgmap"],
  components: {
    RadioSvgMap,
  },
  data() {
    return {
      currentMap: {},
      hoveredMap: {},
      pointedLocation: null,
      focusedLocation: null,
      selectedLocation: null,
      sceneContainer: null,
      style: {},
      backgroundMap: "",
      scene: null,
      tenants: [],
    };
  },
  methods: {
    zoomIn(event) {
      this.scene.zoomIn();
    },
    zoomOut(event) {
      this.scene.zoomOut();
    },
    center(event) {
      this.scene.reset();
      this.pointedLocation = null;
      this.hoveredMap = {};
    },
    pointLocation(event) {
      svgson.parse(this.backgroundMap).then((json) => {
        this.hoveredMap = getHoveredMapConfig.call(this, json);
        this.pointedLocation = getLocation.call(this, event.target);
      });
    },
    unpointLocation(event) {
      this.pointedLocation = null;
      this.hoveredMap = {};
    },
    focusLocation(event) {
      this.pointLocation.call(this, event);
    },
    blurLocation(event) {
      this.unpointLocation.call(this, event);
    },
    getSelectedLocationName,
  },
  watch: {},
  created() {
    const { plan_image, content } = this.svgmap;
    const { tenants } = window;

    this.svgmap["tenants"] = tenants;
    this.tenants = tenants.map(
      (tenant) =>
        (tenant.unit_sqft = tenant.unit_sqft.replace(/.00$/gm, "")) && tenant
    );
    console.log("Tenants: ", this.tenants);
    this.fallback = plan_image;

    axios.get(content).then((res) => {
      if (res.data) {
        this.backgroundMap = res.data;
        svgson.parse(res.data).then((json) => {
          this.currentMap = getCurrentMapConfig.call(this, json);
          this.currentMap.locations = this.currentMap.locations.map(
            addAvaiabilityToSvgMap.bind(this)
          );
        });
        this.sceneContainer = document.getElementById("interactive-map__scene");
        this.scene = panzoom(this.sceneContainer, {
          transformOrigin: { x: 0.5, y: 0.5 },
        });
      }
    });
  },
};
</script>