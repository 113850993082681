import Vue from "vue";
import { headroom } from "vue-headroom";
import BootstrapVue from "bootstrap-vue";
import imagesloaded from "imagesloaded";
import AOS from "aos";
import Flickity from "flickity";
import Splitting from "splitting";
import Properties from "./components/Properties/Properties";
import VueMap from "./components/Properties/Map";
import InteractiveMap from "./components/Properties/InteractiveMap";
import ApolloClient from "apollo-boost";
import VueApollo from "vue-apollo";
import * as VueGoogleMaps from "vue2-google-maps";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons/faPlusSquare";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons/faArrowAltCircleRight";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faMinusSquare } from "@fortawesome/free-regular-svg-icons/faMinusSquare";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons/faDotCircle";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const { origin } = window.location;

library.add(
  faPlusSquare,
  faMinusSquare,
  faDotCircle,
  faArrowAltCircleRight,
  faAngleRight
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCY_7shw8NMKjCLUIMl0kL7ibt_RBvgQKo",
  },
  installComponents: true,
});
Vue.use(VueApollo);

const apolloClient = new ApolloClient({
  uri: `${origin}/graphql`,
});
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

///////////////////////////////
// Initialize
///////////////////////////////

// Vue Boostrap
Vue.use(BootstrapVue);

// AOS
AOS.init({
  duration: 800,
});

// Splitting
Splitting();

// Flickity
window.Flickity = Flickity;

// Imagesloaded
window.imagesLoaded = imagesloaded;

///////////////////////////////
// Functions
///////////////////////////////

// Page Section Background Image Preloader
imagesloaded(".page-section__background", { background: true }, function() {
  const backgrounds = document.querySelectorAll(".page-section__background");
  backgrounds.forEach((item) => {
    item.classList.add("page-section__background--loaded");
  });
});

// Fade out section headers
const header = document.querySelector(".page-header .page-section__background");
if (header) {
  window.addEventListener("scroll", function(e) {
    const offset = $(window).scrollTop();
    if (offset <= header.clientHeight) {
      header.classList.add("scrolling");
      header.style.opacity = 1 - offset / header.clientHeight;
    } else {
      header.classList.remove("scrolling");
    }
  });
}

// DL Dropdowns
const dlDropdowns = document.querySelector(".dl-menuwrapper");
if (dlDropdowns) {
  $(".dl-menuwrapper").dlmenu({
    useActiveItemAsBackLabel: true,
  });
}

// Navbar
const navbar = document.querySelector("#vue-navbar");
if (navbar) {
  new Vue({
    name: "Navbar",
    el: "#vue-navbar",
    components: {
      headroom,
    },
    mounted() {
      const sections = document.querySelectorAll("[data-section]");
      const navbar = document.querySelector("#vue-navbar");
      const windowHeight = window.innerHeight;
      // const navbarObserver = new IntersectionObserver((entries) => {
      //   entries.forEach(entry => {
      //     if (entry.intersectionRatio > 0) {
      //       const sectionColor = entry.target.dataset.section;
      //       const nav = navbar.querySelector('.navbar');
      //       if (sectionColor === 'dark') {
      //         nav.classList.remove('navbar-light');
      //         nav.classList.add('navbar-dark');
      //       } else {
      //         nav.classList.remove('navbar-dark');
      //         nav.classList.add('navbar-light');
      //       }
      //     }
      //   })
      // }, {
      //   rootMargin: `0px 0px -${windowHeight - navbar.clientHeight}px 0px`
      // });
      // sections.forEach((section) => {
      //   navbarObserver.observe(section);
      // });
    },
  });
}

// Events
const properties = document.querySelectorAll(".vue-properties");
if (properties) {
  properties.forEach((el) => {
    new Vue({
      el,
      apolloProvider,
      render: (h) => h(Properties),
      components: {
        Properties,
      },
    });
  });
}

// Vue Map Component
const vueMapComponent = document.querySelectorAll(".vue-map-section");
if (vueMapComponent) {
  vueMapComponent.forEach((el) => {
    new Vue({
      el,
      apolloProvider,
      render: (h) => h(VueMap),
      components: {
        VueMap,
      },
    });
  });
}

// Vue Map Component
const interactiveMap = document.querySelectorAll(".JS-interactive-map");
if (interactiveMap) {
  interactiveMap.forEach((el) => {
    new Vue({
      el,
      components: {
        InteractiveMap,
      },
    });
  });
}

// Accordions
const accordions = document.querySelectorAll(".vue-accordion");
if (accordions) {
  accordions.forEach((el, i) => {
    new Vue({
      el,
      data() {
        return {
          instance: i,
        };
      },
    });
  });
}

// Cycle
var progress = $("#progress"),
  slideshow = $(".cycle-slideshow");

slideshow.on("cycle-initialized cycle-before", function(e, opts) {
  progress.stop(true).css("width", 0);
});

slideshow.on("cycle-initialized cycle-after", function(e, opts) {
  if (!slideshow.is(".cycle-paused"))
    progress.animate({ width: "100%" }, opts.timeout, "linear");
});

slideshow.on("cycle-paused", function(e, opts) {
  progress.stop();
});

slideshow.on("cycle-resumed", function(e, opts, timeoutRemaining) {
  progress.animate({ width: "100%" }, timeoutRemaining, "linear");
});

const searchForm = $("#searchform");
if (searchForm && !window.location.href.includes("/news")) {
  const $submitButton = searchForm.find("#searchsubmit");
  $submitButton.on("click", function(e) {
    event.preventDefault();
    let search = "";
    $("[name=search]").each(function(e) {
      if ($(this).val()) {
        search = $(this).val();
      }
    });
    window.location.href = `${origin}/properties?search=${search}`;
  });
}

//------------------------------------//
//Select Boxes//
//------------------------------------//
$(".sel").each(function() {
  $(this)
    .children("select")
    .css("display", "none");

  var $current = $(this);

  $(this)
    .find("option")
    .each(function(i) {
      if (i == 0) {
        $current.prepend(
          $("<div>", {
            class: $current.attr("class").replace(/sel/g, "sel__box"),
          })
        );

        var placeholder = $(this).text();
        $current.prepend(
          $("<span>", {
            class: $current.attr("class").replace(/sel/g, "sel__placeholder"),
            text: placeholder,
            "data-placeholder": placeholder,
          })
        );

        return;
      }

      $current.children("div").append(
        $("<span>", {
          class: $current.attr("class").replace(/sel/g, "sel__box__options"),
          text: $(this).text(),
        })
      );
    });
});

// Toggling the `.active` state on the `.sel`.
$(".sel").click(function() {
  $(this).toggleClass("active");
});

// Toggling the `.selected` state on the options.
$(".sel__box__options").click(function() {
  var txt = $(this).text();
  var index = $(this).index();

  $(this)
    .siblings(".sel__box__options")
    .removeClass("selected");
  $(this).addClass("selected");

  var $currentSel = $(this).closest(".sel");
  $currentSel.children(".sel__placeholder").text(txt);
  $currentSel.children("select").prop("selectedIndex", index + 1);
});

//drawer
$(document).ready(function() {
  $(".drawer").drawer();
});

$(".drawer").drawer({
  class: {
    nav: "drawer-nav",
    toggle: "drawer-toggle",
    overlay: "drawer-overlay",
    open: "drawer-open",
    close: "drawer-close",
    dropdown: "drawer-dropdown",
  },
  iscroll: {
    // Configuring the iScroll
    // https://github.com/cubiq/iscroll#configuring-the-iscroll
    mouseWheel: true,
    preventDefault: false,
  },
  showOverlay: false,
});

//------------------------------------//
//Object Fit IE Fix//
//------------------------------------//
if (window && window.Modernizr && !Modernizr.objectfit) {
  $(".image-container").each(function() {
    var $container = $(this),
      imgUrl = $container.find("img").prop("src");
    if (imgUrl) {
      $container
        .css("backgroundImage", "url(" + imgUrl + ")")
        .addClass("compat-object-fit");
    }
  });
}

//scroll top
$(window).scroll(function() {
  $(".scroll-image").css("opacity", 1 - $(window).scrollTop() / 60);
  $(".scroll-text").css("opacity", 1 - $(window).scrollTop() / 60);
});

//number counter on scroll
jQuery(window).scroll(startCounter);
function startCounter() {
  var hT = jQuery(".counter").offset() && jQuery(".counter").offset().top,
    hH = jQuery(".counter").outerHeight(),
    wH = jQuery(window).height();
  if (jQuery(window).scrollTop() > hT + hH - wH) {
    jQuery(window).off("scroll", startCounter);
    jQuery(".count").each(function() {
      var $this = jQuery(this);
      jQuery({ Counter: 0 }).animate(
        { Counter: $this.text() },
        {
          duration: 2000,
          easing: "swing",
          step: function() {
            $this.text(Math.ceil(this.Counter));
          },
        }
      );
    });
  }
}

const dropdowns = document.querySelectorAll(".JS-dropdown");
if (dropdowns) {
  dropdowns.forEach((element) => {
    new Vue({
      el: element,
    });
  });
}
