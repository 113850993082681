<template>
  <div class="d-flex">
    <div class="map-container" style="width: 100%; min-height: 600px;">
      <GmapMap
        :center="{lat:propertyCoords.lat, lng:propertyCoords.lng}"
        :zoom="7"
        style="width: 100%; height: 100%"
        :options="{styles: mapStyles}"
      >
        <GmapMarker
          :position="propertyCoords"
          :clickable="true"
          @click="center=propertyCoords"
          :icon="{ url: '/wp-content/themes/sterling-organization/src/components/Properties/assets/marker-sm.png' }"
        />
      </GmapMap>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import gql from "graphql-tag";
import mapStyles from "./map-styles";

export default {
  props: ["coords"],
  components: {},
  data() {
    return {
      propertyCoords,
      mapStyles
    };
  },
  watch: {},
  created() {
    if (window.propertyCoords) {
      this.propertyCoords = Object.keys(window.propertyCoords).reduce(
        (acc, cv) => {
          switch (cv) {
            case "latitude":
              acc["lat"] = parseFloat(window.propertyCoords[cv]);
            case "longitude":
              acc["lng"] = parseFloat(window.propertyCoords[cv]);
          }
          return acc;
        },
        {}
      );
    }
  }
};
</script>