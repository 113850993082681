import mapStyles from '../map-styles';

export const options = {
	shouldSort: true,
	threshold: 0.3,
	location: 0,
	distance: 1000,
	maxPatternLength: 32,
	minMatchCharLength: 3,
	caseSensitive: false,
	keys: ['title', 'city', 'msa', 'state', 'stateLong', 'zip'],
};

export const anchors_options = {
	shouldSort: true,
	threshold: 0,
	location: 0,
	distance: 1000,
	maxPatternLength: 32,
	minMatchCharLength: 3,
	caseSensitive: false,
	keys: ['anchors'],
};

export const availability = {
	selected: 'Any',
	options: [{ name: 'Any' }, { name: 'Available' }, { name: 'Not Available' }],
};

export const agents = {
	selected: 'Any',
	options: [],
};

export const tenant_filter = {
	selected: 'any',
	options: [
		{
			name: 'Any',
			value: 'any',
		},
		{
			name: 'Under 1,000 Sq. Ft.',
			value: 'option_1',
		},
		{
			name: '1,001 - 2,000 Sq. Ft.',
			value: 'option_2',
		},
		{
			name: '2,001 - 3,000 Sq. Ft.',
			value: 'option_3',
		},
		{
			name: '3,001 - 4,000 Sq. Ft.',
			value: 'option_4',
		},
		{
			name: '4,001 - 5,000 Sq. Ft.',
			value: 'option_5',
		},
		{
			name: '5,001 - 6,000 Sq. Ft.',
			value: 'option_6',
		},
		{
			name: '6,001 - 7,000 Sq. Ft.',
			value: 'option_7',
		},
		{
			name: '7,001 - 8,000 Sq. Ft.',
			value: 'option_8',
		},
		{
			name: '8,001 - 9,000 Sq. Ft.',
			value: 'option_9',
		},
		{
			name: '9,001 - 10,000 Sq. Ft.',
			value: 'option_10',
		},
		{
			name: '10,001 - 20,000 Sq. Ft.',
			value: 'option_11',
		},
		{
			name: '20,001 - 30,000 Sq. Ft.',
			value: 'option_12',
		},
		{
			name: '30,001 - 40,000 Sq. Ft.',
			value: 'option_13',
		},
		{
			name: '40,001 - 50,000 Sq. Ft.',
			value: 'option_14',
		},
		{
			name: 'Above 50,000 Sq. Ft.',
			value: 'option_15',
		},
	],
};

export const GetProperties = `
query GetProperties {
    properties(first: 100) {
      nodes {
				yardiId
        title
        city
        state
        cityState
        stateLong
        link
        gla
        msa
        anchors {
          nodes {
            name
          }
        }
        latitude
        longitude
        squareFootage
        availability
        propertyImage
        zip
				propertyManager
				leasingManager
				leasingAssociate
        tenants {
          option_1
          option_2
          option_3
          option_4
          option_5
          option_6
          option_7
          option_8
          option_9
          option_10
          option_11
          option_12
          option_13
          option_14
          option_15
        }
      }
    }
  }
`;

export const data = {
	allFilters: {},
	displayedProperties: [],
	properties: [],
	availability,
	square_ft_range: tenant_filter,
	search: '',
	anchors: '',
	agents,
	loaded: false,
	view: 'grid',
	mapStyles,
	infoWindowPos: null,
	infoWinOpen: false,
	currentMidx: null,
	fields: [
		{
			key: 'name',
			sortable: false,
			label: 'Name',
		},
		{
			key: 'city',
			sortable: false,
			label: 'City',
		},
		{
			key: 'state',
			sortable: false,
			label: 'State',
		},
		{
			key: 'gla',
			sortable: false,
			label: 'GLA',
		},
		{
			key: 'msa',
			sortable: false,
			label: 'MSA',
		},
		{
			key: 'availability',
			sortable: false,
			label: 'Space Available',
		},
	],
	infoOptions: {
		content: '',
		pixelOffset: {
			width: 0,
			height: -35,
		},
	},
};
