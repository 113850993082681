var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex" }, [
    _c(
      "div",
      {
        staticClass: "map-container",
        staticStyle: { width: "100%", "min-height": "600px" },
      },
      [
        _c(
          "GmapMap",
          {
            staticStyle: { width: "100%", height: "100%" },
            attrs: {
              center: {
                lat: _vm.propertyCoords.lat,
                lng: _vm.propertyCoords.lng,
              },
              zoom: 7,
              options: { styles: _vm.mapStyles },
            },
          },
          [
            _c("GmapMarker", {
              attrs: {
                position: _vm.propertyCoords,
                clickable: true,
                icon: {
                  url: "/wp-content/themes/sterling-organization/src/components/Properties/assets/marker-sm.png",
                },
              },
              on: {
                click: function ($event) {
                  _vm.center = _vm.propertyCoords
                },
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }