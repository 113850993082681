export const yardiOrder = [
  587,
  316,
  604,
  597,
  727,
  970,
  322,
  603,
  410,
  561,
  432,
  1901,
  317,
  6,
  3,
  588,
  91,
  21,
  256,
  74,
  381,
  563,
  583,
  219,
  554,
  579,
  11,
  711,
  308,
  84,
  858,
  444,
  319,
  209,
  235,
  328,
  247,
  250,
  327,
  212,
  985,
  70,
  574,
  439,
  607,
  602,
  598,
  969,
  60,
  120,
  86,
  433,
  315,
  14,
  614,
  608,
  220,
  426,
  845,
  139,
  663,
  844,
  10,
  966,
  8,
  1016,
  661,
  446,
  434,
  152,
  221,
  7,
  12,
  965,
  833,
  741,
  981,
  834,
  984,
];

export function mapOrder(array, order, key) {
  array.sort(function(a, b) {
    var A = parseInt(a[key]),
      B = parseInt(b[key]);
    if (a["title"] === "Fridley Market") {
      console.log("Yardi ID", parseInt(a[key]));
    }
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
}

export function updateSearch(item, type) {
  // Make sure the allFilters data property is up to date
  this.updateAllFilters();
  // check if the item has a value
  if (item) {
    if (type === "availability") {
      this[type].selected = item;
    }
    if (type === "square_ft_range") {
      this[type].selected = item.value;
    }
    if (type === "agents") {
      this[type].selected = item;
    }
    this.displayedProperties = this.reEvaluateFilters(type);
  } else if (this.allFiltersClean()) {
    this.displayedProperties = this.reEvaluateFilters(type);
  } else {
    this.displayedProperties = this.reEvaluateFilters(type);
  }
  // Hack to make sure that SVG's are maintained after refresh
  if (this.view == "list") {
    setTimeout(this.updateAvailabilitySvgs, 0);
  }
}

export function addAvaiabilityToSvgMap(location) {
  const correspondingLocation = this.tenants.filter((tenant) => {
    return (
      `tenant-${tenant.unit_code}` === location.id ||
      `tenant-${tenant.unit_code}_1_` === location.id ||
      `tenant-${tenant.unit_code}_2_` === location.id
    );
  })[0];
  if (
    correspondingLocation &&
    correspondingLocation.tenant_name.toLowerCase().includes("available")
  ) {
    location.id = `${location.id}-available`;
  }
  return location;
}

export const inputSearch = {
  get() {
    return this.search;
  },
  set(newVal) {
    this.search = newVal;
    this.updateSearch(this.search, "search");
  },
};

/**
 * Get the name of the targeted location
 *
 * @param {Node} node - HTML node
 * @returns {string} Name of the location
 */
export function getLocationName(node) {
  return node && node.attributes.id.value;
}

export function getHoveredMapPaths(child) {
  if (child.name !== "path") {
    console.warn(`<${child.name}> tag will be ignored`);
    return false;
  }
  if (event.target.id.indexOf("-available") !== -1) {
    let target = event.target.id.split("-available");
    return child.attributes.id === target[0];
  }
  if (child.attributes.id !== event.target.id) {
    return false;
  }
  return true;
}

export function getMapPaths(child) {
  if (child.name === "style") {
    this.style = child;
  }
  if (child.name !== "path") {
    console.warn(`<${child.name}> tag will be ignored`);
    return false;
  }
  return true;
}

export function getCurrentMapConfig(json) {
  return {
    label: json.attributes["aria-label"],
    viewBox: json.attributes.viewBox,
    locations: json.children
      .filter(getMapPaths.bind(this))
      .map(formatLocationData),
  };
}

export function getHoveredMapConfig(json) {
  return {
    label: json.attributes["aria-label"],
    viewBox: json.attributes.viewBox,
    locations: json.children
      .filter(getHoveredMapPaths.bind(this))
      .map(formatLocationData),
  };
}

export function getLocation(target) {
  const locationName = getLocationName(event.target);
  const locationNameArray = locationName.split("-");
  let locationCode = locationNameArray[1];

  if (locationNameArray.includes("available")) {
    const idx = locationNameArray.indexOf("available");
    locationNameArray.splice(idx, 1);
  }

  if (locationNameArray.length > 2) {
    locationCode = `${locationNameArray[1]}-${locationNameArray[2]}`;
  }

  if (locationCode.includes("_")) {
    locationCode = locationCode.split("_")[0];
  }

  const location = this.tenants.filter((tenant) => {
    return tenant.unit_code === locationCode;
  })[0];

  return location;
}

export function formatLocationData(child) {
  return {
    name: child.name,
    id: child.attributes.id,
    path: child.attributes.d,
  };
}
/**
 * Get the name of the selected location
 *
 * @param {Object} map - Map of component
 * @param {string} locationId - Id of selected location
 * @returns {string} Name of the selected location
 */
export function getSelectedLocationName(map, locationId) {
  return (
    locationId &&
    map.locations.find((location) => location.id === locationId).name
  );
}
